<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col cols="12">
          <v-card class="rounded-lg shadow">
            <v-card-title>
              <div>
                <v-text-field v-model="search"
                              append-icon="mdi-magnify"
                              class="w-300"
                              clearable
                              dense
                              filled
                              hide-details
                              placeholder="Recherche..."
                              rounded single-line></v-text-field>
              </div>
              <v-spacer/>
              <v-btn class="text-none" disabled text>
                {{ deletion_requests.length }} Élément(s)
              </v-btn>
              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="getDeletionRequests" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>

            </v-card-title>

            <v-skeleton-loader v-if="loading" type="table"/>

            <v-data-table v-else :headers="headers" :items="deletion_requests" :search="search">
              <template v-slot:item.status="{ item }">

                <v-chip v-if="item.status === 'pending'" color="orange" dark small>
                  <v-icon left small>mdi-timer-sand</v-icon>
                  En attente
                </v-chip>

                <v-chip v-if="item.status === 'deleted'" color="success" dark small>
                  <v-icon left small>mdi-account-remove-outline</v-icon>
                  Supprimé
                </v-chip>

                <v-chip v-if="item.status === 'refused'" color="red" dark small>
                  <v-icon left small>mdi-cancel</v-icon>
                  Refusé
                </v-chip>

              </template>


              <template v-slot:item.actions="{ item }">
                <div class="d-flex">

                  <v-tooltip color="primary" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs"
                             @click="deleteItem(item.id,'refused')"
                             v-on="on">
                        <v-icon size="20">mdi-cancel</v-icon>
                      </v-btn>
                    </template>
                    <span>Refuser</span>
                  </v-tooltip>

                  <v-tooltip color="primary" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs"
                             @click="deleteItem(item.id,'deleted')"
                             v-on="on">
                        <v-icon size="20">mdi-account-remove-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Supprimer le compte</span>
                  </v-tooltip>

                  <v-tooltip color="primary" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs"
                             @click="deleteItem(item.id,'destroyed')"
                             v-on="on">
                        <v-icon size="20">mdi-delete-forever-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Supprimer la demande</span>
                  </v-tooltip>

                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      search: '',
      headers: [
        {text: 'Téléphone', value: 'phone'},
        {text: 'Statut', value: 'status'},
        {text: 'Refusé / Supprimé le', value: 'status_updated_at'},
        {text: 'Date', value: 'created_at'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      overlay: false,
      dialog: false,

      id: '',
      actionType: '',
      deletion_requests: [],
      loading: false,
    }
  },
  methods: {
    deleteItem(id, actionType) {
      this.actionType = actionType
      this.id = id
      this.$confirm_dialog = true

      let text = actionType === "refused" ? "REFUSER LA DEMANDE" : actionType === "deleted" ? "SUPPRIMER LE COMPTE" : "SUPPRIMER LA DEMANDE"
      this.$confirm_dialog_body = "Êtes-vous sûr de vouloir " + text
    },
    getDeletionRequests() {
      this.loading = true
      this.deletion_requests = []
      this.$Progress.start()
      HTTP.get('/deletion-requests').then((res) => {
        this.$Progress.finish()
        this.deletion_requests = res.data.data
        this.loading = false
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    }
  },
  created() {
    this.getDeletionRequests()
  },
  watch: {
    '$is_confirm': function (el) {
      if (el) {
        this.$is_confirm = false
        this.overlay = true
        this.$Progress.start()
        HTTP.post('/deletion-requests/delete/', {
          id: this.id,
          action_type: this.actionType
        }).then(() => {
          this.$successMessage = 'Cette action a été terminée avec succès'
          this.getDeletionRequests()
          this.overlay = false
          this.$Progress.finish()
        }).catch(err => {
          this.overlay = false
          this.$Progress.fail()
          console.log(err)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>